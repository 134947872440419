// extracted by mini-css-extract-plugin
export var logo = "authorList-module--logo--3EPdH";
export var spacer = "authorList-module--spacer--gnbRn";
export var toTopWrap = "authorList-module--toTopWrap---VcNH";
export var opacity1 = "authorList-module--opacity-1--W4S+X";
export var toTopButton = "authorList-module--toTopButton--dqMuv";
export var toTopText = "authorList-module--toTopText--1fIri";
export var authorListHeader = "authorList-module--authorListHeader--Lstka";
export var sectionNavigationWrap = "authorList-module--sectionNavigationWrap--wNDmx";
export var sectionNavigation = "authorList-module--section-navigation--SwUJW";
export var authorListTitle = "authorList-module--authorListTitle--gKIk0";
export var authorListSubhead = "authorList-module--authorListSubhead--jQMJG";
export var bgWhite = "authorList-module--bgWhite--GJyGZ";
export var bgGrey = "authorList-module--bgGrey--c-nlE";
export var container = "authorList-module--container--+FcYe";
export var sectionHeading = "authorList-module--sectionHeading--qo2HX";
export var black = "authorList-module--black--nSjbx";
export var white = "authorList-module--white--z87LY";
export var bioImage = "authorList-module--bioImage--+518Q";
export var staffName = "authorList-module--staffName--Z6umE";
export var staffPosition = "authorList-module--staffPosition--C4G7S";
export var socialWrap = "authorList-module--socialWrap--aoUbj";
export var primaryStaff = "authorList-module--primaryStaff--rEWMz";
export var primaryItem = "authorList-module--primaryItem--x1Cen";
export var advisorStaffInset = "authorList-module--advisorStaffInset--0kO+X";
export var advisorItem = "authorList-module--advisorItem--NYfEn";
export var fellowsStaffInset = "authorList-module--fellowsStaffInset--ZLRsd";
export var fellowItem = "authorList-module--fellowItem--hKsTQ";
export var directorsStaffInset = "authorList-module--directorsStaffInset--5oRVy";
export var directorItem = "authorList-module--directorItem--sPY27";
export var projectsCallout = "authorList-module--projectsCallout--jHt09";
export var projectsCalloutImage = "authorList-module--projectsCalloutImage--EJKfw";
export var projectsCalloutHeading = "authorList-module--projectsCalloutHeading--Zz7Ib";
export var projectsButtonWrap = "authorList-module--projectsButtonWrap--Wllqr";
export var textLine = "authorList-module--textLine--gcKhP";
export var slideUp = "authorList-module--slideUp--F6ye8";
export var slideUpLarge = "authorList-module--slideUpLarge--ClvDE";